import type { DynamicPageMeta } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import { IS_SERVER } from '@/constants/env';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { logger } from '@/core/features/logger/logger';
import { C24GeneralTrackingBodyPixel } from '@/core/features/tracking/c24-general-tracking';
import { GoogleTagManagerBodyScript } from '@/core/features/tracking/google-tag-manager';
import useCsCodeTracking from '@/core/features/tracking/tracking-hooks/use-cs-code-tracking';
import usePPSETTracking from '@/core/features/tracking/tracking-hooks/use-ppset-tracking';
import useStorageMeta from '@/core/features/tracking/tracking-hooks/use-storage-meta';
import useTidTracking from '@/core/features/tracking/tracking-hooks/use-tid-tracking';
import useVirtualPageviewTracking from '@/core/features/tracking/tracking-hooks/use-virtual-pageview-tracking';
import useWpsetTracking from '@/core/features/tracking/tracking-hooks/use-wpset-tracking';
import useXPPSETTracking from '@/core/features/tracking/tracking-hooks/use-xppset-tracking';

export default function TrackingContainer({ metaData, url }: { metaData: DynamicPageMeta | null; url: string }) {
    const { deviceoutput, isDesktopView } = useDeviceoutput();

    useStorageMeta(metaData);
    useWpsetTracking(metaData);
    useTidTracking(metaData);
    useVirtualPageviewTracking(metaData);
    usePPSETTracking();
    useXPPSETTracking({ isDesktopView });
    useCsCodeTracking(metaData);

    if (!metaData?.trackingMeta && IS_SERVER) {
        logger.error(`Dynamic Page MetaData missing in TrackingContainer for url ${url}`);
    }

    // add ?activity=...&spot=... to GT url to make it react to changes
    const additionalSearchParams: Record<string, string> = {};
    if (metaData?.trackingMeta.activity) {
        // eslint-disable-next-line fp/no-mutation
        additionalSearchParams['activity'] = metaData.trackingMeta.activity;
    }
    if (metaData?.trackingMeta.spot) {
        // eslint-disable-next-line fp/no-mutation
        additionalSearchParams['spot'] = metaData.trackingMeta.spot;
    }

    return (
        <>
            {metaData && <GoogleTagManagerBodyScript />}
            {metaData?.trackingMeta && (
                <C24GeneralTrackingBodyPixel
                    additionalSearchParams={additionalSearchParams}
                    deviceoutput={deviceoutput}
                    trackingMeta={metaData.trackingMeta}
                />
            )}
        </>
    );
}
