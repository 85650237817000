import type { DynamicPageMeta } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { VirtualPageviewParameters } from '@/core/features/tracking/google-data-layer';

import React from 'react';

import { useCookiesMapped, useUser } from '@/core/features/app/app-atoms';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { logger } from '@/core/features/logger/logger';
import { useRouterEvents } from '@/core/features/router/router-events';
import { trackVirtualPageview } from '@/core/features/tracking/google-data-layer';

export default function useVirtualPageviewTracking(metaData: DynamicPageMeta | null) {
    const routerEvents = useRouterEvents();
    const [user] = useUser();
    const { deviceoutput } = useDeviceoutput();
    const cookiesMapped = useCookiesMapped();
    const { indiSession } = cookiesMapped;

    const pendingVirtualPageviewTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
    const lastTrackedVirtualPageviewRef = React.useRef<null | string>(null);

    const trackingHandler = React.useCallback(() => {
        if (!metaData) {
            logger.error('metaData is missing in trackVirtualPageviewEvent');
            return;
        }

        if (!indiSession) {
            logger.error('indiSession is missing in trackVirtualPageviewEvent', {
                additionalData: JSON.stringify({ metaData }),
            });
            return;
        }

        if (!user?.loginStatus) {
            logger.error('loginStatus is missing in trackVirtualPageviewEvent', {
                additionalData: JSON.stringify({ metaData }),
            });
            return;
        }

        const virtualPageviewParameters: VirtualPageviewParameters = {
            deviceoutput,
            login: user.loginStatus,
            sid: indiSession,
        };

        const nextTrackedVirtualPageview = JSON.stringify({
            ...metaData.trackingMeta,
            ...virtualPageviewParameters,
        });

        if (nextTrackedVirtualPageview === lastTrackedVirtualPageviewRef.current) {
            // e.g if filters are selected this data will not change and should not track twice
            return;
        }

        trackVirtualPageview(metaData.trackingMeta, virtualPageviewParameters, 'use-virtual-pageview-tracking');

        lastTrackedVirtualPageviewRef.current = nextTrackedVirtualPageview;
    }, [deviceoutput, indiSession, metaData, user.loginStatus]);

    const clearTrackingEvent = () => {
        if (pendingVirtualPageviewTimeoutRef.current === null) {
            return;
        }

        clearTimeout(pendingVirtualPageviewTimeoutRef.current);
        pendingVirtualPageviewTimeoutRef.current = null;
    };
    const debouncedTrackingHandler = React.useCallback(() => {
        clearTrackingEvent();
        pendingVirtualPageviewTimeoutRef.current = setTimeout(() => {
            trackingHandler();
        }, 100);
    }, [trackingHandler]);

    React.useEffect(() => {
        routerEvents.on('routeChangeComplete', debouncedTrackingHandler);

        debouncedTrackingHandler();

        return () => {
            routerEvents.off('routeChangeComplete', debouncedTrackingHandler);
            clearTrackingEvent();
        };
    }, [debouncedTrackingHandler, routerEvents]);
}
