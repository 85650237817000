import React from 'react';

export default function Main({ children, isStorybook }: { children: React.ReactNode; isStorybook?: boolean }) {
    return (
        <main
            className={'block min-height-100dvh width-100'}
            style={isStorybook ? { minHeight: 'unset' } : undefined}
        >
            {children}
        </main>
    );
}
