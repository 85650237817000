import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { UseQueryResult } from '@/core/features/react-query/react-query-service';

import { IS_PROD } from '@/constants/env';
import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import { getComponentClientSide, getComponentQueryKey } from '@/core/features/a-component/services/component-service';
import { useQueries } from '@/core/features/react-query/react-query-service';
import { DEFAULT_QUERY_STALE_TIME } from '@/core/features/request/request-constants';
import { getURL } from '@/core/utils/url';

type ComponentQuery<Component extends PageComponent> = UseQueryResult<Component, Error>;

export default function useComponentQueries<Component extends PageComponent>(
    componentIris: string[],
): ComponentQuery<Component>[] {
    return useQueries({
        queries: componentIris.map((componentIri) => {
            const URL = getURL(componentIri);
            const componentId = getComponentId(componentIri);
            const queryKey = getComponentQueryKey(componentIri);
            const queryFn = () => getComponentClientSide<Component>(componentId, URL.searchParams);

            return {
                componentIri,
                enabled: true,
                initialData: undefined,
                queryFn,
                queryKey,
                staleTime: DEFAULT_QUERY_STALE_TIME,
                throwOnError: !IS_PROD,
            };
        }),
    });
}
