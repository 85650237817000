import type { DynamicPageMeta } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import { buildCookieTTL, setBrowserCookie } from '@/core/features/cookies/cookies-service';
import { TIME_1Y } from '@/core/utils/time';

export default function useCsCodeTracking(metaData: DynamicPageMeta | null) {
    React.useEffect(() => {
        const csCode = metaData?.trackingMeta.csCode;

        if (csCode) {
            const expirationDate = buildCookieTTL((TIME_1Y * 100) / 1000); // 100 years -> forever
            setBrowserCookie('cs_code', csCode, { expires: expirationDate, isTopLevel: true });
        }
    }, [metaData]);
}
