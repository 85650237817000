import type { RouteChangeContext } from '@/core/features/router/router-events';

import React from 'react';

import { useRouter as useNextRouter } from 'next/router';

import { useClientUrl } from '@/core/features/app/app-atoms';
import { setFirstAppVisit } from '@/core/features/router/history';
import { useRouterEvents } from '@/core/features/router/router-events';
import { atom, useAtom } from '@/core/features/store/atom-store';

const routerInitStateAtom = atom<boolean>(false);

export default function useRouterInit({ network }: { network: { isLoading: boolean } }) {
    const [wasRouterInit, setRouterInit] = useAtom(routerInitStateAtom);

    const registerRouterInitListenerRef = React.useRef(false);
    const routerInitedRef = React.useRef(false);

    const routeChangeStarted = React.useRef(false);
    const prevClientUrlRef = React.useRef<null | string>(null);
    const isRouteChangePopstate = React.useRef(false);

    const networkLoadingRef = React.useRef(network.isLoading);

    const nextRouter = useNextRouter();
    const [clientUrl, setClientUrl] = useClientUrl();

    const routerEvents = useRouterEvents();

    // store loading state
    React.useEffect(() => {
        networkLoadingRef.current = network.isLoading;
    }, [network.isLoading]);

    // disable next router
    React.useEffect(() => {
        nextRouter.beforePopState(() => {
            return false;
        });
    }, [nextRouter]);

    const pollRouteChangeCompleteRecursive = React.useCallback(() => {
        setTimeout(() => {
            if (!routeChangeStarted.current) {
                isRouteChangePopstate.current = false;
                return;
            }

            if (networkLoadingRef.current) {
                return pollRouteChangeCompleteRecursive();
            }

            routerEvents.emit<RouteChangeContext>('routeChangeComplete', { isPopstate: isRouteChangePopstate.current });
            isRouteChangePopstate.current = false;
            routeChangeStarted.current = false;
        }, 20);
    }, [routerEvents]);

    // emit routeChangeStart
    React.useEffect(() => {
        // skip if clientUrl does not change, e.g. no actual routing happens
        if (prevClientUrlRef.current === clientUrl) {
            return;
        }

        prevClientUrlRef.current = clientUrl;
        routerEvents.emit<RouteChangeContext>('routeChangeStart', { isPopstate: isRouteChangePopstate.current });

        routeChangeStarted.current = true;
        pollRouteChangeCompleteRecursive();
    }, [routerEvents, clientUrl, pollRouteChangeCompleteRecursive]);

    // register popstate
    React.useEffect(() => {
        const popstateHandler = (event: PopStateEvent) => {
            if (!event?.state?.url) {
                return;
            }
            routerEvents.emit('popstate', event);
            if (event.state.url !== clientUrl) {
                isRouteChangePopstate.current = true;
                setClientUrl(event.state.url);
            }
        };

        window.addEventListener('popstate', popstateHandler);
        return () => {
            window.removeEventListener('popstate', popstateHandler);
        };
    }, [routerEvents, setClientUrl, clientUrl]);

    // emit router init
    React.useEffect(() => {
        if (registerRouterInitListenerRef.current === true) {
            return;
        }
        registerRouterInitListenerRef.current = true;

        routerEvents.on('routeChangeComplete', () => {
            if (routerInitedRef.current === false) {
                routerEvents.emit('routerInit');
                routerInitedRef.current = true;
                return;
            }
        });
    }, [routerEvents]);

    // store first app visit in history
    React.useEffect(() => {
        if (wasRouterInit) {
            return;
        }
        setRouterInit(true);
        setFirstAppVisit();
    }, [wasRouterInit, setRouterInit]);
}
