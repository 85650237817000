/* eslint-disable fp/no-mutation */
/* eslint-disable no-console */

import React from 'react';

import { APP_SESSION_COOKIE_NAME } from '@/constants/product';
import { getBrowserCookie } from '@/core/features/cookies/cookies-service';
import { sendClientLogMessage } from '@/core/features/logger/logger';

export default function useClientErrorHandler() {
    React.useEffect(() => {
        window.addEventListener('error', handleClientErrorEvent);

        registerClientErrorLoggerInterceptor();

        return () => {
            window.removeEventListener('error', handleClientErrorEvent);
            unregisterClientErrorLoggerInterceptor();
        };
    }, []);
    return null;
}

const handleClientError = (error: Error) => {
    const indiSession = getBrowserCookie(APP_SESSION_COOKIE_NAME);

    sendClientLogMessage('error', error.message, { indiSession, stackTrace: error.stack, url: location?.href });
};

const handleClientErrorEvent = (error: ErrorEvent) => {
    const indiSession = getBrowserCookie(APP_SESSION_COOKIE_NAME);

    if (error.error) {
        sendClientLogMessage('error', error.error ?? error.message, {
            indiSession,
            stackTrace: error.error.stack,
            url: location?.href,
        });
    } else {
        sendClientLogMessage('error', error.message, { indiSession, url: location?.href });
    }
};

const prevErrorLogger = console.error;

const unregisterClientErrorLoggerInterceptor = () => {
    console.error = prevErrorLogger;
};

const registerClientErrorLoggerInterceptor = () => {
    console.error = (...args) => {
        prevErrorLogger(...args);

        if (Array.isArray(args) && args.length === 1) {
            if (typeof args[0] === 'string') {
                handleClientError(new Error(args[0]));
                return;
            }
            if (args[0] instanceof Error) {
                handleClientError(args[0]);
                return;
            }
            if (args[0] instanceof ErrorEvent) {
                handleClientError(new Error(args[0].error));
                return;
            }
        }
    };
};
