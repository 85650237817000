import type { DynamicPageMeta } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import { IS_SERVER } from '@/constants/env';
import { WPSET_DEFAULT } from '@/constants/product';
import { buildCookieTTL, setBrowserCookie } from '@/core/features/cookies/cookies-service';
import { logger } from '@/core/features/logger/logger';
import { TIME_1Y } from '@/core/utils/time';

export default function useWpsetTracking(metaData: DynamicPageMeta | null) {
    React.useEffect(() => {
        const wpset = getWpset(metaData);

        if (wpset) {
            const expirationDate = buildCookieTTL((TIME_1Y * 100) / 1000); // 100 years -> forever
            setBrowserCookie('wpset', wpset, { expires: expirationDate, isTopLevel: true });
        }
    }, [metaData]);
}

/**
 * Logic Cases for the WPSET Cookie:
 * - Case 1) if API sends any wpset other than the default, set it as a cookie
 *      - either the user had a set wpset cookie already
 *          -> we then provide the api an additionalParamter in the request containing the wpset
 *      - and/or a wpset query parameter was set in the url
 *          -> we give both the cookie and the url to the api so the api decides what wins
 * - Case 2) if we get the default wpset check if it is a google or bing entry by referrer
 * - Case 3) in any other case we assume it was a direct visit without cookies or url parameters
 */
const getWpset = (metaData: DynamicPageMeta | null) => {
    if (IS_SERVER) {
        logger.error('getWpset must not be used on the server');
    }

    // Case 1 api provided wpset
    const metaWpset = metaData?.trackingMeta.wpset;

    if (metaWpset !== WPSET_DEFAULT) {
        return metaWpset;
    }

    // Case 2
    if (document.referrer.includes('google') || document.referrer.includes('bing')) {
        return 'natural';
    }

    // Case 3
    return WPSET_DEFAULT;
};
