import type { DynamicPageMeta } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import { buildCompressedCookieValue, buildCookieTTL, setBrowserCookie } from '@/core/features/cookies/cookies-service';
import { isObject } from '@/core/utils/object';

/**
 * store all properties given by the api in DynamicPage->meta->storageMeta as compressed cookie
 * so these values can be send back to api in every request inside of additionalParameters
 *
 * relevant for SEA tracking (INDI-3349)
 */
export default function useStorageMeta(metaData: DynamicPageMeta | null) {
    const storageMeta = metaData?.storageMeta;

    React.useEffect(() => {
        if (!isObject(storageMeta)) {
            return;
        }

        Object.entries(storageMeta).forEach(([storageKey, storageConfig]) => {
            if (!isObject(storageConfig) || !isObject(storageConfig.data) || typeof storageConfig.ttl !== 'number') {
                return;
            }

            // e.g. c24cams
            setBrowserCookie(storageKey, buildCompressedCookieValue(storageConfig.data), {
                expires: buildCookieTTL(storageConfig.ttl),
                isSecure: true,
                isTopLevel: true,
            });
        });
    }, [storageMeta]);
}
