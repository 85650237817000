import type { DynamicPageProps } from '@/core/features/a-dynamic-page/dynamic-page-types';

import dynamic from 'next/dynamic';

import { logger } from '@/core/features/logger/logger';

const MobileLayoutSwitch = dynamic(
    () => import(/* webpackChunkName: "MobileLayoutSwitch" */ '@/features/page-layouts/mobile/mobile-layout-switch'),
);
const DesktopLayoutSwitch = dynamic(
    () => import(/* webpackChunkName: "DesktopLayoutSwitch" */ '@/features/page-layouts/desktop/desktop-layout-switch'),
);

export default function DeviceoutputSwitch(props: DynamicPageProps) {
    switch (props.ssrProps.cookiesMapped.deviceoutput) {
        case 'mobile':
        case 'app':
            return <MobileLayoutSwitch {...props} />;
        case 'desktop':
        case 'tablet':
        case 'tabletapp':
            return <DesktopLayoutSwitch {...props} />;
        default: {
            const { components: _components, ...debugProps } = props;
            logger.error(`No Layout found for deviceoutput: ${props.ssrProps.cookiesMapped.deviceoutput}`, {
                additionalData: JSON.stringify(debugProps),
                url: debugProps.ssrProps.ssrUrl,
            });
            return <MobileLayoutSwitch {...props} />;
        }
    }
}
