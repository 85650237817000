import type { DynamicPageMeta } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import { setBrowserCookie } from '@/core/features/cookies/cookies-service';

export default function useTidTracking(metaData: DynamicPageMeta | null) {
    const tid = metaData?.trackingMeta.tid;
    React.useEffect(() => {
        if (tid) {
            setBrowserCookie('tid', tid, { expires: 'session', isSecure: false, isTopLevel: true });
        }
    }, [tid]);
}
