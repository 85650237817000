import type { DynamicPageProps } from '@/core/features/a-dynamic-page/dynamic-page-types';

import Main from '@/core/features/page/main';
import DeviceoutputSwitch from '@/features/a-dynamic-page/components/deviceoutput-switch';
import MapContainer from '@/features/map/map-container/map-container';

export default function DynamicPage(props: React.PropsWithChildren<DynamicPageProps>) {
    return (
        <Main>
            <DeviceoutputSwitch {...props} />
            <MapContainer components={props.components} />
        </Main>
    );
}
