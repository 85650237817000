import React from 'react';

import { WIREFRAME_LOADED_CUSTOM_EVENT } from '@packages/wireframe/src/constants';

export default function useWireframeLoaded(callback: () => void, dependencies: unknown[] = []): void {
    React.useEffect(() => {
        if (window.isWireframeLoaded) {
            callback();
        } else {
            window.addEventListener(WIREFRAME_LOADED_CUSTOM_EVENT, callback, {
                once: true,
            });
        }

        return () => window.removeEventListener(WIREFRAME_LOADED_CUSTOM_EVENT, callback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}
