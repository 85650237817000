import React from 'react';

import { NEXT_PUBLIC_SSO_PRODUCT_ID } from '@/constants/env';
import { buildCookieTTL, setBrowserCookie } from '@/core/features/cookies/cookies-service';
import { logger } from '@/core/features/logger/logger';
import { TIME_24H } from '@/core/utils/time';

export default function usePPSETTracking() {
    React.useEffect(() => {
        setPPSET();
    }, []);
}

const setPPSET = () => {
    if (!NEXT_PUBLIC_SSO_PRODUCT_ID) {
        logger.error('setPPSET: NEXT_PUBLIC_SSO_PRODUCT_ID is not defined');
        return;
    }
    const expirationDate = buildCookieTTL((TIME_24H * 5) / 1000);
    setBrowserCookie('ppset', NEXT_PUBLIC_SSO_PRODUCT_ID, {
        expires: expirationDate,
        isSecure: true,
        isTopLevel: true,
    });
};
