import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { PageComponentRegistry } from '@/features/a-dynamic-page/components/page-component-registry-type';

import React from 'react';

import dynamic from 'next/dynamic';

export default function getPageDataComponentByName(name: PageComponent['@type']) {
    return registry[name];
}

/**
 * Page Data Components
 * Components that are not rendered to screen but provide state
 */
const FilterDataV1 = dynamic(
    () => import(/* webpackChunkName: "FilterDataV1" */ '@/features/filter/filter-data/filter-data-v1'),
);

const registry: PageComponentRegistry = {
    FilterDataV1: {
        lazyConfig: {
            disableLazyContainer: true,
            minHeight: 0, // not used
            type: 'eager',
        },
        render: React.memo(FilterDataV1),
    },
    MapDataV5: { render: null }, // no rendering needed as it happens inside the map component
    OnpageSearchDataV1: { render: null }, // no rendering needed as it happens inside the onpage search component
};
