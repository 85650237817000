export const GoogleTagManagerHeadScript = () => {
    return (
        <script
            async
            src={'/google-tag-manager.js'}
        />
    );
};

export const GoogleTagManagerBodyScript = () => {
    return (
        <noscript
            dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NLS5CDC"
                 height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}
        />
    );
};
