import React from 'react';

import { ErrorPage } from '@/core/features';
import Main from '@/core/features/page/main';

const ErrorBoundaryDefaultFallback = (): JSX.Element | null => {
    return (
        <Main>
            <ErrorPage
                detail={'Boundary'}
                isPageLoading={false}
                statusCode={500}
            />
        </Main>
    );
};

export default ErrorBoundaryDefaultFallback;
