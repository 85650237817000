/* eslint-disable react/no-unstable-nested-components */
import type { FallbackProps } from 'react-error-boundary';

import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorBoundaryDefaultFallback from '@/core/features/error-boundary/error-boundary-default-fallback';
import useClientErrorHandler from '@/core/features/error-boundary/use-client-error-handler';
import { logger } from '@/core/features/logger/logger';

type ErrorBoundaryProps = {
    children: React.ReactNode | React.ReactNode[];
    fallbackRender?: (props: FallbackProps) => JSX.Element | null;
};

export default function ErrorBoundaryWrapper({ children, fallbackRender }: ErrorBoundaryProps) {
    useClientErrorHandler();

    return (
        <ErrorBoundary
            fallbackRender={(props) => (fallbackRender ? fallbackRender(props) : <ErrorBoundaryDefaultFallback />)}
            onError={errorBoundaryHandler}
        >
            {children}
        </ErrorBoundary>
    );
}

const errorBoundaryHandler = (error: Error) => {
    logger.error(`${error.message} (${error})`);
};
